<template>
  <BaseDialog
    :propDialog="dialog"
    :max-width="800"
    :title="$t('invoice.payment-proof')"
    :actionable="false"
    @closeDialog="setDialog"
  >
    <div class="d-flex justify-center">
      <InnerImageZoom :src="imgUrl" />
    </div>
  </BaseDialog>
</template>

<script>
import InnerImageZoom from 'vue-inner-image-zoom'
import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css'

export default {
  name: 'PaymentProof',

  components: {
    InnerImageZoom
  },

  props: {
    dialog: Boolean,
    imgUrl: String
  },

  methods: {
    setDialog(val) {
      this.$emit('update:dialog', val)
    }
  }
}
</script>

<style scoped></style>
