<template>
  <BaseDialog
    :propDialog="dialog"
    :max-width="400"
    title="invoice.confirm-payment"
    :validity="valid"
    :loader="loading"
    @confirm="updateInvoice"
    @closeDialog="setDialog"
  >
    <BaseEntry label="invoice.table" :content="invoice.tableName" />
    <BaseEntry label="invoice.customer" :content="invoice.customerName" />
    <BaseEntry label="invoice.invoice-num" :content="invoice.invoiceNum" />
    <BaseEntry label="invoice.total" :content="invoiceTotal.toLocaleString()" />
    <v-form class="mt-4" ref="form" v-model="valid" @submit.prevent>
      <BaseTextInput
        label="common.note"
        v-model="cloneInvoice.note"
        :character-limit="99"
      />
      <BaseCurrencyInput
        outlined
        label="invoice.received"
        v-model="receivedAmount"
        required
        currency="VND"
      />
      <v-radio-group
        class="mt-0 pt-0"
        v-model="cloneInvoice.paymentMethod"
        mandatory
      >
        <v-radio
          v-for="option in paymentOptions"
          class="mb-3"
          :key="option.code"
          :value="option.code"
          ><template v-slot:label>
            <v-row dense
              ><v-col
                align-self="center"
                cols="6"
                :class="selectMethod(option) ? 'primary--text' : ''"
              >
                {{ option.name }}
              </v-col>
              <v-col cols="6">
                <v-icon :color="selectMethod(option) ? 'primary' : ''"
                  >${{ option.icon }}</v-icon
                ></v-col
              >
            </v-row>
          </template></v-radio
        >
      </v-radio-group>
      <BaseFileInput label="invoice.payment-proof" v-model="file" />
      <BaseImagePreview
        :class="viewableImage ? 'clickable' : ''"
        :imgUrl="invoice.paymentProof"
        :imgFile="file"
        @click.stop="viewPaymentProof"
        @error="errorImg = true"
        ref="imagePreview"
      />
    </v-form>
    <PaymentProof
      v-if="paymentProofDialog"
      :dialog.sync="paymentProofDialog"
      :imgUrl="invoice.paymentProof"
    />
  </BaseDialog>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import { displayErrorMessage, updateInvoice, createForm } from '@/utils'
import scrollIntoView from 'smooth-scroll-into-view-if-needed'
import PaymentProof from './PaymentProof.vue'

export default {
  name: 'ConfirmPayment',

  components: {
    PaymentProof
  },

  props: {
    invoice: Object,
    dialog: Boolean
  },

  data() {
    return {
      cloneInvoice: {},
      valid: true,
      loading: false,
      file: null,
      errorImg: false,
      paymentProofDialog: false,
      paymentOptions: [
        {
          code: 'cash',
          name: this.$t('invoice.cash'),
          icon: 'money'
        },
        {
          code: 'wire',
          name: this.$t('invoice.wire'),
          icon: 'phone'
        },
        {
          code: 'card',
          name: this.$t('invoice.card'),
          icon: 'card'
        }
      ]
    }
  },

  computed: {
    receivedAmount: {
      get() {
        return this.invoice.receivedAmount || this.invoiceTotal
      },
      set(val) {
        this.cloneInvoice.receivedAmount = val
      }
    },

    invoiceTotal() {
      let total = this.invoice.entries
      return total.reduce(
        (acc, item) => acc + item.finalQuantity * item.price,
        0
      )
    },

    viewableImage() {
      return this.invoice.paymentProof && !this.file && !this.errorImg
    }
  },

  watch: {
    invoice: {
      immediate: true,
      handler: function(val) {
        this.cloneInvoice = cloneDeep(val)
        this.cloneInvoice.receivedAmount = this.invoiceTotal
      }
    },

    file(val) {
      if (val)
        this.$nextTick().then(() => scrollIntoView(this.$refs.imagePreview.$el))
    }
  },

  methods: {
    setDialog(val) {
      this.$emit('update:dialog', val)
    },

    selectMethod(option) {
      return option.code === this.cloneInvoice.paymentMethod
    },

    viewPaymentProof() {
      if (this.viewableImage) {
        this.paymentProofDialog = true
      }
    },

    updateInvoice() {
      this.loading = true
      if (this.cloneInvoice.receivedAmount > 0) {
        this.cloneInvoice.status = 'paid'
      } else {
        this.cloneInvoice.status = 'cancelled'
      }
      delete this.cloneInvoice.entries
      const form = createForm(this.cloneInvoice, this.file)

      updateInvoice(this.cloneInvoice._id, form)
        .then(() => this.setDialog(false))
        .catch(err => displayErrorMessage(err))
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<style lang="scss" scoped></style>
